<template>
  <v-container>
    <v-row align="center">
      <v-col offset-sm="3" cols="12" sm="3">
        <v-card style="height: 200px;">
          <v-layout align-center justify-center column fill-height>
            <v-flex row align-center>
              <v-btn to="/importexport/importwizard" large color="primary">
                <v-icon class="mr-3">mdi-database-import</v-icon>Import
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card style="height: 200px;">
          <v-layout align-center justify-center column fill-height>
            <v-flex row align-center>
              <v-btn large color="primary">
                <v-icon class="mr-3">mdi-database-export</v-icon>Export
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Overview",
  data: () => ({}),
  components: {},
  methods: {},
  computed: {},
  beforeCreate() {},
};
</script>
<style scoped></style>
